import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { BlogCta } from "./helpdesk-problems-solutions";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";
import { redirectToWithUtm } from "../../../components/Header";
const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");

const header_image = require("../../../assets/img/blogs/hr_help_desk_blog_header.png");
const section_1 = require("../../../assets/img/blogs/hr_help_desk_blog_image_1.png");
const section_2 = require("../../../assets/img/blogs/hr_help_desk_blog_image_2.png");
const section_3 = require("../../../assets/img/blogs/hr_help_desk_blog_image_3.png");
const section_4 = require("../../../assets/img/blogs/hr_help_desk_blog_image_4.png");
const section_5 = require("../../../assets/img/blogs/hr_help_desk_blog_image_5.png");
const section_6 = require("../../../assets/img/blogs/hr_help_desk_blog_image_6.png");
const section_7 = require("../../../assets/img/blogs/hr_help_desk_blog_image_7.png");
const section_8 = require("../../../assets/img/blogs/hr_help_desk_blog_image_8.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");
const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="HR Help Desk: The Ultimate Guide 2023"
        description="Transform your HR help desk with Workativ Assistant’s AI chatbot. Boost employee efficiency and productivity using automation. Try it today!"
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section className="w-100 float-left blog_top_header_upt hr_help_desk_blog_header_upt">
            <div className="container">
              <div
                className={`flex_cont_top_blog_img text-align-center ${
                  isMobile ? "" : "float-left"
                }`}
              >
                <h1
                  className={`mb-2 ${
                    isMobile
                      ? "font-section-sub-header-blog-title"
                      : "font-section-sub-header-blog-title"
                  }`}
                >
                  The ultimate guide - 2023
                </h1>
                <h2
                  className={` ${
                    isMobile
                      ? "font-page-header-home-blog"
                      : "font-page-header-home-blog"
                  }`}
                >
                  HR HELP DESK
                </h2>
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}
          {isMobile ? <Faq /> : null}
          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. What is an HR help desk?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. Why is the HR help desk important for your business?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. How does the human resource help desk benefit your team?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. What are some of the common challenges in the HR help desk?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. How to optimize your HR help desk for a streamlined
                  employee experience?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. What are the key benefits of an automated hr help desk?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section7"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section7"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section7")}
                >
                  7. What are some examples of automated hr help desk use cases?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section8"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section8"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section7")}
                >
                  8. How to build a human resource helpdesk chatbot?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section9"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section9"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section7")}
                >
                  9. Leverage Workativ Assistant to help transform the HR
                  experience
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Human resource management is always a hard job to handle. From
                resolving calls to being a reason for employee experience, HR
                experience also matters. HR help desk supposedly makes HR
                operations easy and provides a better way to manage employee
                communication and collaboration and drive business outcomes.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                In this article, we will uncover how your HR operations can
                maximize the HR help desk to drive efficiency and productivity
                and offer the best way to reimagine your human resource help
                desk for cost savings, employee engagement, and retention.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                What is an HR help desk?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Once just an IT help desk version to help with HR operations,
                the HR help desk today focuses on improving human resource
                queries using tools and technologies.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Your internal help desk for hr can act as a central repository
                of information that your employees need to{" "}
                <b>
                  solve queries regarding Payroll, Tax calculations, PTO, and
                  Leave Management 一{" "}
                </b>
                and to say more specifically, the HR help desk reduces effort
                and wait time when it comes to resolving the most mundane hr
                processes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
                Also,{" "}
                <a href="https://workativ.com/conversational-ai-platform/conversational-ai">
                  with more modern and sophisticated embedded solutions such as
                  self-service capabilities and automation,
                </a>{" "}
                the{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/help-desk-automation-employee-experience">
                  hr help desk can reimagine the employee experience.
                </a>{" "}
                They can reduce operational costs that your HR teams would
                otherwise waste primarily due to the lack of real timeliness of
                solutions.
              </p>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="HR help desk optimization with chatbot builder "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Take note if your HR help desk uses a legacy approach, it costs
                you no significant investments to translate your existing system
                into an <b>automated HR help desk support.</b> All you need is
                build{" "}
                <a href="https://workativ.com/conversational-ai-platform/chatbot-builder">
                  a scalable chatbot to offer conversational dialog management
                  for your HR and employees.
                </a>{" "}
                We will explore more about this option later in this article.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why is the HR help desk important for your business?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                More often than not, the HR activities include taking care of
                onboarding, offboarding, managing workshops, and delegating
                low-priority responsibilities to other team members while also
                ensuring each employee optimizes their time with full potential.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                However, responsibilities entail more than just overseeing
                operational processes. Undoubtedly, it has greater
                responsibilities to optimize resources and save costs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                According to BambooHR stats,{" "}
                <a href="https://www.bamboohr.com/blog/onboarding-infographic">
                  effective onboarding translates into 18x more commitment
                </a>{" "}
                from new hires to the employer, increasing engagement and
                retention rates.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                If your HR workflows are inefficient, it results in delayed
                response, causing employee frustration and disengagement,
                denying greater control over labor costs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Ineffective onboarding may hit back at your bottom line in many
                ways 一
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  A new hire is time-consuming and expensive, which takes an
                  average of{" "}
                  <a href="https://www.shrm.org/ResourcesAndTools/business-solutions/Documents/Talent-Acquisition-Report-All-Industries-All-FTEs.pdf">
                    36 days to fill a position while costing $4,425.
                  </a>{" "}
                </li>
                <li className="font-section-normal-text-testimonials">
                  {" "}
                  <a href="https://www.shrm.org/ResourcesAndTools/business-solutions/Documents/Talent-Acquisition-Report-All-Industries-All-FTEs.pdf">
                    About 16% of employees may quit in their first week{" "}
                  </a>
                </li>
                <li className="font-section-normal-text-testimonials">
                  <a href="https://www.shrm.org/ResourcesAndTools/business-solutions/Documents/Talent-Acquisition-Report-All-Industries-All-FTEs.pdf">
                    17% of employees are highly likely to quit after a month
                  </a>
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                It means you need to refill these positions, which costs
                businesses an employee’s annual salary of up to $140,000, as per
                <a href="https://www.shrm.org/hr-today/trends-and-forecasting/special-reports-and-expert-views/Documents/Retaining-Talent.pdf">
                  an employee attrition report.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                So, leveraging the HR help desk can free up your team to
                prioritize workflows and manage efficiency, thus creating
                engaged employee experience and business value.
              </p>
            </div>

            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How does the human resource help desk benefit your team?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Your employees need support. An HR help desk truly fits the
                need.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                HR help desk drives employee engagement
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                According to Gallup,{" "}
                <a href="https://www.gallup.com/workplace/393497/world-trillion-workplace-problem.aspx">
                  disengaged employees can cost $7.8 trillion in lost
                  productivity.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Also, a recent survey by{" "}
                <a href="https://hbr.org/2022/06/why-microsoft-measures-employee-thriving-not-engagement">
                  Microsoft states that employees seek meaningful work to
                  thrive.
                </a>{" "}
                So, they want to contribute, and the best way to keep your
                employees engaged is to provide the information they need.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Fortunately, you can build a nurturing and thriving culture in
                your workplace by centralizing processes and helping your
                employee find information with a{" "}
                <a href="http://web.workativ.ai/conversational-ai-platform/blog/hr-service-management-guide">
                  human resource service desk.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, confidential matters such as maternity leave
                management are something that employees want to keep under
                wraps. Employees secretly want to know the length of leaves and
                benefits without making it public across the department. But
                what if they communicate via back-and-forth emails without
                getting any positive response? When they get a response, it
                already does the damage and creates emotional stress.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                An HR help desk can give you the ability to organize your
                information, save your time, and fast-track the process of
                maternity leave management. As a result, you can improve
                employee experience and build employee engagement.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                HR support desk boosts employee retention
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                Assume you have a new employee onboarding, and the new hire
                expects engaging interactions on the first day. A list of
                necessary actions goes this way 一 sending welcome messages,
                providing a way to connect with stakeholders over a few days of
                joining, and a better way to know your organization.
                Unfortunately, too many administrative duties can interrupt your
                time and restrict you from catering to significant employee
                needs initially.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With an HR help desk, you can use the essential tools to
                customize and streamline workflows that help you provide all
                necessary resources and manage your onboarding efficiently. One
                positive experience builds long-term trust and loyalty, thus
                allowing you to reflect on company values and augment employee
                confidence for better retention.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                <a href="https://workativ.com/conversational-ai-platform/blog/10-ways-to-use-ai-chatbots-for-internal-it-and-hr-support">
                  {" "}
                  The internal help desk welcomes feedback
                </a>
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                More than salary, a thriving and ambient workplace always draws
                employees’ attention. They believe a workplace that creates
                employee value through opinion and feedback facilitates better
                engagement and mutual growth.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Thinking of running an employee feedback session may not work
                out, owing to the challenges in connecting with people across
                every department and bringing them to the same board. Imagine
                that even if you get them on board, there is a doubt that they
                can share some glaring issues in the open. You can miss out on
                important messages and not be able to take action to bring any
                changes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But, sending out a survey feedback notification through your{" "}
                <b>HR help desk</b> portal makes it easier for employees to
                participate and provide essential information.{" "}
                <b>
                  Employees may elaborate more on administrative issues or any
                  kind of discriminatory behavior if any.
                </b>{" "}
                As a result, taking action and building a sustainable workplace
                culture is faster and easier.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With all these and more you can accomplish with the HR help
                desk, your HR processes can become streamlined and less
                labor-intensive with various tools. Ticketing systems reporting
                and analytics are two value-added features to help you gain
                flexibility at scale.
              </p>
              <BlogCta
                ContentCta="Auto-resolve Employee Queries With AI Chatbot."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What are some of the common challenges in the HR help desk?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                When you aim to{" "}
                <b>drive holistic value from your so-called HR help desk,</b>{" "}
                the legacy architecture <b>may need more scalability.</b>
                Optimizing your internal{" "}
                <a href="https://workativ.com/conversational-ai-platform/chatbot-marketplace-templates">
                  HR help desk software with automation
                </a>
                can unleash more personalized solutions. We will discuss this
                later, though; let’s know the shortcomings.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Self-service capabilities
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                {" "}
                <a href="https://www.pwc.com/us/en/tech-effect/cloud/hr-tech-survey.html">
                  As reported by PwC,
                </a>{" "}
                19% of HR staff surveyed talk about the significance of
                self-service capabilities that are missing from their current HR
                tools.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Reluctance to adoption
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                HR leaders expect to increase their investment in{" "}
                <a href="http://web.workativ.ai/conversational-ai-platform/blog/helpdesk-problems-solutions">
                  HR tech solutions.
                </a>{" "}
                But, a survey reported that{" "}
                <a href="https://www.pwc.com/us/en/tech-effect/cloud/hr-tech-survey.html">
                  training and development is a challenge, which makes it
                  difficult to drive user adoption.
                </a>
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Traditional IT support desk
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Most businesses still use their existing IT support desk as an
                HR tool. The solution does not have tools and features to
                support the growing HR challenges.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Growing agent involvement
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                With the existing HR support help desk, agents unnecessarily
                need to manage, monitor and resolve tickets for the first level
                of support. They need to handle them manually, which delays the
                response. Additionally,<b>escalation is tough to manage.</b>
              </p>
              <h3 className="font-section-sub-header-small-home">
                Managed service providers
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                As you outsource managed service support, it becomes expensive
                with the growing burden of managing tickets in real time.
                Security and privacy issues are also other levels of headaches.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How to optimize your HR help desk for a streamlined employee
                experience?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                It only takes a few iterations to embrace a modernized hr help
                desk.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Leverage app workflow automation
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                To manage the growing volume of HR operations, app workflow
                automation can prove beneficial to automate HR processes. It is
                easier to create using low-code or no-code canvas or interface
                while, at the same time, it enables you to achieve
                conversational AI capabilities.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Embed in your chatbot
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                By syncing your HR help desk with the most familiar
                communication channels, you can offer resolutions at your
                fingertips. When your employees need more detailed information
                about an app or company policy, it is more accessible to fetch
                via a chatbot interaction that produces a knowledge link and
                helps them proceed.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Automate ticket responses
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                When you employ automation for your HR apps, you can easily
                fetch the information to your chatbot system. No matter the
                issue, your employee can raise the ticket within the chatbot
                without leaving their app and accelerate the feedback.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Apply reporting and analytics
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                <a href="http://web.workativ.ai/conversational-ai-platform/blog/help-desk-vs-service-desk">
                  Automated HR help desk is a treasure trove of sheer
                </a>{" "}
                data volume. You can analyze data and improve your HR responses.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                What are the key benefits of an automated hr help desk?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                You can leverage the key benefits of the hr help desk by
                integrating it with automation technologies.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Onboarding is faster and seamless
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                No matter whether it is a remote or onsite onboarding, you can
                quickly reduce wait time for the new hire and HR executive.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                More than anything, your HR staff no longer needs to dedicate
                long hours interacting with the new hire. It just takes a few
                minutes to hand over details through{" "}
                <a href="https://workativ.com/conversational-ai-platform/outlook-chatbot">
                  automated email responses.
                </a>
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="human resource help desk with automated onboarding capabilities"
              />
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li>
                  Enable app provisioning with detailed login and password
                  information
                </li>
                <li>Send a welcome video</li>
                <li>
                  Send an invitation for stakeholder communication and
                  collaboration
                </li>
                <li>
                  Provide a link to knowledgebase to build cognizance about
                  company culture and policy
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home">
                Rapid HR request resolution with self-service capabilities
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                Automated HR help desk can offer frictionless employee
                experience. With the ability to embed with your business
                communication channels like Teams and Slack, you can easily
                automate several mundane activities your HR staff conducts. AI
                conversation chatbot makes it easy to automate basic-level
                support activities.
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="internal help desk with embedded self-service automation capabilities"
              />
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li>
                  Instantly reset the password without waiting for an hr staff
                </li>
                <li>
                  {" "}
                  Get support to resolve account unlock issues or printer issues{" "}
                </li>
                <li>
                  Enable your employees to create a ticket to an hr staff when
                  issues turn out to be complicated
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home">
                Faster escalation of tickets to an expert
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                When things are tough to handle through DIY attempts, AI
                conversational platform can be designed to{" "}
                <a href="https://workativ.com/conversational-ai-platform/ticket-automation-chatbot">
                  sort tickets and categorize the level of priority.
                </a>{" "}
                You can apply workflow automation to implement this capability
                and pass the ticket to the right agent at the right time.
              </p>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="ticket escalation with an automated chatbot for the HR help desk "
              />
              <p class="font-section-normal-text-testimonials line-height-18 ">
                The magic of the workflow is that as soon as the issue gets
                resolved, the ticket also gets closed.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                What are some examples of automated hr help desk use cases?
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                Modernizing your HR help desk with an automated chatbot
                unleashes multiple ways to increase user efficiency and
                productivity.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Accelerate response for payroll inquiry
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
                With a traditional help desk, when an employee shoots a payroll
                inquiry, it usually takes several days, if not months. The
                reason it slips through the HR staff’s attention as it lands in
                the inbox bombarded with an influx of emails. Then there follows
                a couple of interactions back and forth via emails or phone
                calls.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                If it is the case, you can imagine the level of employee
                frustration.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                <a href="https://workativ.com/use-cases/payroll-enquiry-automation">
                  An automated HR help desk that uses chatbot integration can
                </a>
                easily manage payroll inquiries and provide all the necessary
                information to the employee.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="Automated HR help desk for payroll management "
              />
              <p class="font-section-normal-text-testimonials line-height-18">
                You can create a FAQ-based knowledge base and apply automation
                to it. Trigger workflow when your employee escalates an inquiry
                and gives details via email or directly within your chatbot.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Seamless employee offboarding management
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                It is a very familiar scenario when your employee has to
                postpone their onboarding to the company they are likely to
                join. In another instance, if your HR member is absent,
                offboarding cannot occur. The situation may result in extra days
                allotted for that particular employee, forcing you to pay for
                those additional days. By automating the offboarding process,
                you can quickly reduce the risk of adding more to your
                operational costs.
              </p>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="HR help desk with automated employee offboarding solutions."
              />
              <p class="font-section-normal-text-testimonials line-height-18">
                Simply create app workflows for your HRSM tools like BambooHR
                and Zoho People, and manage offboarding seamlessly. If needed,
                you can communicate via personalized email.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                As soon as the employee is omitted from the HRSM tools, an app
                trigger sends out workflows and helps you speed up the process
                without involving your HR agent.
              </p>
            </div>
            <div className="divs_blogs_upt float-left mb-3" id="section8">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How to build a human resource helpdesk chatbot?
              </h2>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="HR help desk chatbot builder "
              />
              <p class="font-section-normal-text-testimonials line-height-18">
                It needs you to determine your scope of HR operations,
                considering where you want to{" "}
                <a href="http://web.workativ.ai/conversational-ai-platform/blog/hr-digital-transformation-guide-2023">
                  implement automation to transform the employee experience.
                </a>{" "}
                Generally, as a CHRO, you must seek to automate the most mundane
                work process. Surprisingly, Workativ Assistant, a conversational
                AI platform, is a powerful tool to set you up and running with
                your HR bot in just a few hours.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                All you need is to follow a few steps.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                    Sign up to create a new bot or download from the marketplace
                  </a>
                </li>
                <li>
                  Using pre-built app integrations and app workflows, you can
                  connect your chatbot and automate responses (for say, connect
                  BambooHR, Freshdesk, Zoho People, etc.)
                </li>
                <li>
                  Test your bot and make adjustments or edit conversations
                  before you go live
                </li>
                <li className="mb-0">
                  Turn on your favorite business comms channels and embed the
                  chatbot to allow your employees to leverage self-service
                  capabilities
                </li>
              </ul>
              <img
                src={section_8}
                className="blog_image_top_bt"
                alt="internal human resource help desk development with automation capabilities "
              />
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                If you want a visual guide to build your HR help desk chatbot,
                <a href="https://youtu.be/ymkNx2zMLAE">
                  watch this video below.
                </a>
              </p>
            </div>
            <NocodeWrapper />
            <div className="divs_blogs_uptfloat-left" id="section9">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Leverage Workativ Assistant to help transform the HR experience
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ is a low-code and no-code automated chatbot platform.
                Our AI-powered conversational chatbot interface provides a
                variety of tools, for say, chatbot builder, chatbot templates,
                and app workflow within one platform with cloud-based deployment
                flexibility. It means no upfront costs are involved, eliminating
                the need to keep aside a huge chunk of the budget for
                operational expenses. Pay only subscriptions and tap into the
                exponential potential to transform your employee and HR
                experience.
              </p>

              <h3 class=" font-section-sub-header-small-home line-height-18">
                Workativ’s competitive advantage
              </h3>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li>
                  Within the first year of deployment, Workativ helps{" "}
                  <b>reduce call volumes by 40%</b>
                </li>
                <li>
                  Achieve a <b>20% y-o-y increase in call reduction</b>
                </li>
                <li>
                  With high-level self-service capabilities, the{" "}
                  <b>
                    chatbot tool increases employee satisfaction level by 4
                    points
                  </b>
                </li>
                <li>
                  Gain up to 20-30% of HR labor reduction in the first year of
                  deployment
                </li>
              </ul>

              <p class="font-section-normal-text-testimonials line-height-18">
                On top of it, our conversational AI chatbot platform is capable
                of auto-resolving 60-80% of repetitive user issues and requests
                in seconds, freeing up valuable and expensive live agents. Thus,
                improving SLA, cost savings, and helping companies scale their
                support in minutes.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
                To learn more about our conversational AI solution for your HR
                help desk,{" "}
                <a href="https://assistant.workativ.com/authentication/u/direct-signup">
                  schedule a free demo today.
                </a>
              </p>
            </div>
            <Faq />
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}

function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. What is an HR help desk?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. Why is the HR help desk important for your business?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. How does the human resource help desk benefit your team?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. What are some of the common challenges in the HR help
                    desk?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. How to optimize your HR help desk for a streamlined
                    employee experience?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. What are the key benefits of an automated hr help desk?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section7"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section7"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section7")}
                  >
                    7. What are some examples of automated hr help desk use
                    cases?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section8"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section8"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section7")}
                  >
                    8. How to build a human resource helpdesk chatbot?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section9"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section9"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section7")}
                  >
                    9. Leverage Workativ Assistant to help transform the HR
                    experience
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Human resource management is always a hard job to handle. From
                resolving calls to being a reason for employee experience, HR
                experience also matters. HR help desk supposedly makes HR
                operations easy and provides a better way to manage employee
                communication and collaboration and drive business outcomes.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                In this article, we will uncover how your HR operations can
                maximize the HR help desk to drive efficiency and productivity
                and offer the best way to reimagine your human resource help
                desk for cost savings, employee engagement, and retention.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                What is an HR help desk?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Once just an IT help desk version to help with HR operations,
                the HR help desk today focuses on improving human resource
                queries using tools and technologies.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Your internal help desk for hr can act as a central repository
                of information that your employees need to{" "}
                <b>
                  solve queries regarding Payroll, Tax calculations, PTO, and
                  Leave Management 一{" "}
                </b>
                and to say more specifically, the HR help desk reduces effort
                and wait time when it comes to resolving the most mundane hr
                processes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
                Also,{" "}
                <a href="https://workativ.com/conversational-ai-platform/conversational-ai">
                  with more modern and sophisticated embedded solutions such as
                  self-service capabilities and automation,
                </a>{" "}
                the{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/help-desk-automation-employee-experience">
                  hr help desk can reimagine the employee experience.
                </a>{" "}
                They can reduce operational costs that your HR teams would
                otherwise waste primarily due to the lack of real timeliness of
                solutions.
              </p>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="HR help desk optimization with chatbot builder "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Take note if your HR help desk uses a legacy approach, it costs
                you no significant investments to translate your existing system
                into an <b>automated HR help desk support.</b> All you need is
                build{" "}
                <a href="https://workativ.com/conversational-ai-platform/chatbot-builder">
                  a scalable chatbot to offer conversational dialog management
                  for your HR and employees.
                </a>{" "}
                We will explore more about this option later in this article.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why is the HR help desk important for your business?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                More often than not, the HR activities include taking care of
                onboarding, offboarding, managing workshops, and delegating
                low-priority responsibilities to other team members while also
                ensuring each employee optimizes their time with full potential.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                However, responsibilities entail more than just overseeing
                operational processes. Undoubtedly, it has greater
                responsibilities to optimize resources and save costs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                According to BambooHR stats,{" "}
                <a href="https://www.bamboohr.com/blog/onboarding-infographic">
                  effective onboarding translates into 18x more commitment
                </a>{" "}
                from new hires to the employer, increasing engagement and
                retention rates.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                If your HR workflows are inefficient, it results in delayed
                response, causing employee frustration and disengagement,
                denying greater control over labor costs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Ineffective onboarding may hit back at your bottom line in many
                ways 一
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  A new hire is time-consuming and expensive, which takes an
                  average of{" "}
                  <a href="https://www.shrm.org/ResourcesAndTools/business-solutions/Documents/Talent-Acquisition-Report-All-Industries-All-FTEs.pdf">
                    36 days to fill a position while costing $4,425.
                  </a>{" "}
                </li>
                <li className="font-section-normal-text-testimonials">
                  {" "}
                  <a href="https://www.shrm.org/ResourcesAndTools/business-solutions/Documents/Talent-Acquisition-Report-All-Industries-All-FTEs.pdf">
                    About 16% of employees may quit in their first week{" "}
                  </a>
                </li>
                <li className="font-section-normal-text-testimonials">
                  <a href="https://www.shrm.org/ResourcesAndTools/business-solutions/Documents/Talent-Acquisition-Report-All-Industries-All-FTEs.pdf">
                    17% of employees are highly likely to quit after a month
                  </a>
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                It means you need to refill these positions, which costs
                businesses an employee’s annual salary of up to $140,000, as per
                <a href="https://www.shrm.org/hr-today/trends-and-forecasting/special-reports-and-expert-views/Documents/Retaining-Talent.pdf">
                  an employee attrition report.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                So, leveraging the HR help desk can free up your team to
                prioritize workflows and manage efficiency, thus creating
                engaged employee experience and business value.
              </p>
            </div>

            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How does the human resource help desk benefit your team?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Your employees need support. An HR help desk truly fits the
                need.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                HR help desk drives employee engagement
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                According to Gallup,{" "}
                <a href="https://www.gallup.com/workplace/393497/world-trillion-workplace-problem.aspx">
                  disengaged employees can cost $7.8 trillion in lost
                  productivity.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Also, a recent survey by{" "}
                <a href="https://hbr.org/2022/06/why-microsoft-measures-employee-thriving-not-engagement">
                  Microsoft states that employees seek meaningful work to
                  thrive.
                </a>{" "}
                So, they want to contribute, and the best way to keep your
                employees engaged is to provide the information they need.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Fortunately, you can build a nurturing and thriving culture in
                your workplace by centralizing processes and helping your
                employee find information with a{" "}
                <a href="http://web.workativ.ai/conversational-ai-platform/blog/hr-service-management-guide">
                  human resource service desk.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, confidential matters such as maternity leave
                management are something that employees want to keep under
                wraps. Employees secretly want to know the length of leaves and
                benefits without making it public across the department. But
                what if they communicate via back-and-forth emails without
                getting any positive response? When they get a response, it
                already does the damage and creates emotional stress.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                An HR help desk can give you the ability to organize your
                information, save your time, and fast-track the process of
                maternity leave management. As a result, you can improve
                employee experience and build employee engagement.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                HR support desk boosts employee retention
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                Assume you have a new employee onboarding, and the new hire
                expects engaging interactions on the first day. A list of
                necessary actions goes this way 一 sending welcome messages,
                providing a way to connect with stakeholders over a few days of
                joining, and a better way to know your organization.
                Unfortunately, too many administrative duties can interrupt your
                time and restrict you from catering to significant employee
                needs initially.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With an HR help desk, you can use the essential tools to
                customize and streamline workflows that help you provide all
                necessary resources and manage your onboarding efficiently. One
                positive experience builds long-term trust and loyalty, thus
                allowing you to reflect on company values and augment employee
                confidence for better retention.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                <a href="https://workativ.com/conversational-ai-platform/blog/10-ways-to-use-ai-chatbots-for-internal-it-and-hr-support">
                  {" "}
                  The internal help desk welcomes feedback
                </a>
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                More than salary, a thriving and ambient workplace always draws
                employees’ attention. They believe a workplace that creates
                employee value through opinion and feedback facilitates better
                engagement and mutual growth.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Thinking of running an employee feedback session may not work
                out, owing to the challenges in connecting with people across
                every department and bringing them to the same board. Imagine
                that even if you get them on board, there is a doubt that they
                can share some glaring issues in the open. You can miss out on
                important messages and not be able to take action to bring any
                changes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But, sending out a survey feedback notification through your{" "}
                <b>HR help desk</b> portal makes it easier for employees to
                participate and provide essential information.{" "}
                <b>
                  Employees may elaborate more on administrative issues or any
                  kind of discriminatory behavior if any.
                </b>{" "}
                As a result, taking action and building a sustainable workplace
                culture is faster and easier.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With all these and more you can accomplish with the HR help
                desk, your HR processes can become streamlined and less
                labor-intensive with various tools. Ticketing systems reporting
                and analytics are two value-added features to help you gain
                flexibility at scale.
              </p>
              <BlogCta
                ContentCta="Auto-resolve Employee Queries With AI Chatbot."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What are some of the common challenges in the HR help desk?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                When you aim to{" "}
                <b>drive holistic value from your so-called HR help desk,</b>{" "}
                the legacy architecture <b>may need more scalability.</b>
                Optimizing your internal{" "}
                <a href="https://workativ.com/conversational-ai-platform/chatbot-marketplace-templates">
                  HR help desk software with automation
                </a>
                can unleash more personalized solutions. We will discuss this
                later, though; let’s know the shortcomings.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Self-service capabilities
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                {" "}
                <a href="https://www.pwc.com/us/en/tech-effect/cloud/hr-tech-survey.html">
                  As reported by PwC,
                </a>{" "}
                19% of HR staff surveyed talk about the significance of
                self-service capabilities that are missing from their current HR
                tools.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Reluctance to adoption
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                HR leaders expect to increase their investment in{" "}
                <a href="http://web.workativ.ai/conversational-ai-platform/blog/helpdesk-problems-solutions">
                  HR tech solutions.
                </a>{" "}
                But, a survey reported that{" "}
                <a href="https://www.pwc.com/us/en/tech-effect/cloud/hr-tech-survey.html">
                  training and development is a challenge, which makes it
                  difficult to drive user adoption.
                </a>
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Traditional IT support desk
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Most businesses still use their existing IT support desk as an
                HR tool. The solution does not have tools and features to
                support the growing HR challenges.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Growing agent involvement
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                With the existing HR support help desk, agents unnecessarily
                need to manage, monitor and resolve tickets for the first level
                of support. They need to handle them manually, which delays the
                response. Additionally,<b>escalation is tough to manage.</b>
              </p>
              <h3 className="font-section-sub-header-small-home">
                Managed service providers
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                As you outsource managed service support, it becomes expensive
                with the growing burden of managing tickets in real time.
                Security and privacy issues are also other levels of headaches.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How to optimize your HR help desk for a streamlined employee
                experience?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                It only takes a few iterations to embrace a modernized hr help
                desk.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Leverage app workflow automation
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                To manage the growing volume of HR operations, app workflow
                automation can prove beneficial to automate HR processes. It is
                easier to create using low-code or no-code canvas or interface
                while, at the same time, it enables you to achieve
                conversational AI capabilities.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Embed in your chatbot
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                By syncing your HR help desk with the most familiar
                communication channels, you can offer resolutions at your
                fingertips. When your employees need more detailed information
                about an app or company policy, it is more accessible to fetch
                via a chatbot interaction that produces a knowledge link and
                helps them proceed.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Automate ticket responses
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                When you employ automation for your HR apps, you can easily
                fetch the information to your chatbot system. No matter the
                issue, your employee can raise the ticket within the chatbot
                without leaving their app and accelerate the feedback.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Apply reporting and analytics
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                <a href="http://web.workativ.ai/conversational-ai-platform/blog/help-desk-vs-service-desk">
                  Automated HR help desk is a treasure trove of sheer
                </a>{" "}
                data volume. You can analyze data and improve your HR responses.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                What are the key benefits of an automated hr help desk?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                You can leverage the key benefits of the hr help desk by
                integrating it with automation technologies.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Onboarding is faster and seamless
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                No matter whether it is a remote or onsite onboarding, you can
                quickly reduce wait time for the new hire and HR executive.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                More than anything, your HR staff no longer needs to dedicate
                long hours interacting with the new hire. It just takes a few
                minutes to hand over details through{" "}
                <a href="https://workativ.com/conversational-ai-platform/outlook-chatbot">
                  automated email responses.
                </a>
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="human resource help desk with automated onboarding capabilities"
              />
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li>
                  Enable app provisioning with detailed login and password
                  information
                </li>
                <li>Send a welcome video</li>
                <li>
                  Send an invitation for stakeholder communication and
                  collaboration
                </li>
                <li>
                  Provide a link to knowledgebase to build cognizance about
                  company culture and policy
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home">
                Rapid HR request resolution with self-service capabilities
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                Automated HR help desk can offer frictionless employee
                experience. With the ability to embed with your business
                communication channels like Teams and Slack, you can easily
                automate several mundane activities your HR staff conducts. AI
                conversation chatbot makes it easy to automate basic-level
                support activities.
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="internal help desk with embedded self-service automation capabilities"
              />
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li>
                  Instantly reset the password without waiting for an hr staff
                </li>
                <li>
                  {" "}
                  Get support to resolve account unlock issues or printer issues{" "}
                </li>
                <li>
                  Enable your employees to create a ticket to an hr staff when
                  issues turn out to be complicated
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home">
                Faster escalation of tickets to an expert
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                When things are tough to handle through DIY attempts, AI
                conversational platform can be designed to{" "}
                <a href="https://workativ.com/conversational-ai-platform/ticket-automation-chatbot">
                  sort tickets and categorize the level of priority.
                </a>{" "}
                You can apply workflow automation to implement this capability
                and pass the ticket to the right agent at the right time.
              </p>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="ticket escalation with an automated chatbot for the HR help desk "
              />
              <p class="font-section-normal-text-testimonials line-height-18 ">
                The magic of the workflow is that as soon as the issue gets
                resolved, the ticket also gets closed.
              </p>
            </div>
            <div className="divs_blogs_uptfloat-left" id="section7">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                What are some examples of automated hr help desk use cases?
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                Modernizing your HR help desk with an automated chatbot
                unleashes multiple ways to increase user efficiency and
                productivity.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Accelerate response for payroll inquiry
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
                With a traditional help desk, when an employee shoots a payroll
                inquiry, it usually takes several days, if not months. The
                reason it slips through the HR staff’s attention as it lands in
                the inbox bombarded with an influx of emails. Then there follows
                a couple of interactions back and forth via emails or phone
                calls.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                If it is the case, you can imagine the level of employee
                frustration.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                <a href="https://workativ.com/use-cases/payroll-enquiry-automation">
                  An automated HR help desk that uses chatbot integration can
                </a>
                easily manage payroll inquiries and provide all the necessary
                information to the employee.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="Automated HR help desk for payroll management "
              />
              <p class="font-section-normal-text-testimonials line-height-18">
                You can create a FAQ-based knowledge base and apply automation
                to it. Trigger workflow when your employee escalates an inquiry
                and gives details via email or directly within your chatbot.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Seamless employee offboarding management
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                It is a very familiar scenario when your employee has to
                postpone their onboarding to the company they are likely to
                join. In another instance, if your HR member is absent,
                offboarding cannot occur. The situation may result in extra days
                allotted for that particular employee, forcing you to pay for
                those additional days. By automating the offboarding process,
                you can quickly reduce the risk of adding more to your
                operational costs.
              </p>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="HR help desk with automated employee offboarding solutions."
              />
              <p class="font-section-normal-text-testimonials line-height-18">
                Simply create app workflows for your HRSM tools like BambooHR
                and Zoho People, and manage offboarding seamlessly. If needed,
                you can communicate via personalized email.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                As soon as the employee is omitted from the HRSM tools, an app
                trigger sends out workflows and helps you speed up the process
                without involving your HR agent.
              </p>
            </div>
            <div className="divs_blogs_upt float-left mb-3" id="section8">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How to build a human resource helpdesk chatbot?
              </h2>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="HR help desk chatbot builder "
              />
              <p class="font-section-normal-text-testimonials line-height-18">
                It needs you to determine your scope of HR operations,
                considering where you want to{" "}
                <a href="http://web.workativ.ai/conversational-ai-platform/blog/hr-digital-transformation-guide-2023">
                  implement automation to transform the employee experience.
                </a>{" "}
                Generally, as a CHRO, you must seek to automate the most mundane
                work process. Surprisingly, Workativ Assistant, a conversational
                AI platform, is a powerful tool to set you up and running with
                your HR bot in just a few hours.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                All you need is to follow a few steps.
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                    Sign up to create a new bot or download from the marketplace
                  </a>
                </li>
                <li>
                  Using pre-built app integrations and app workflows, you can
                  connect your chatbot and automate responses (for say, connect
                  BambooHR, Freshdesk, Zoho People, etc.)
                </li>
                <li>
                  Test your bot and make adjustments or edit conversations
                  before you go live
                </li>
                <li className="mb-0">
                  Turn on your favorite business comms channels and embed the
                  chatbot to allow your employees to leverage self-service
                  capabilities
                </li>
              </ul>
              <img
                src={section_8}
                className="blog_image_top_bt"
                alt="internal human resource help desk development with automation capabilities "
              />
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                If you want a visual guide to build your HR help desk chatbot,
                <a href="https://youtu.be/ymkNx2zMLAE">
                  watch this video below.
                </a>
              </p>
            </div>
            <NocodeWrapper />
            <div className="divs_blogs_uptfloat-left" id="section9">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Leverage Workativ Assistant to help transform the HR experience
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ is a low-code and no-code automated chatbot platform.
                Our AI-powered conversational chatbot interface provides a
                variety of tools, for say, chatbot builder, chatbot templates,
                and app workflow within one platform with cloud-based deployment
                flexibility. It means no upfront costs are involved, eliminating
                the need to keep aside a huge chunk of the budget for
                operational expenses. Pay only subscriptions and tap into the
                exponential potential to transform your employee and HR
                experience.
              </p>

              <h3 class=" font-section-sub-header-small-home line-height-18">
                Workativ’s competitive advantage
              </h3>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li>
                  Within the first year of deployment, Workativ helps{" "}
                  <b>reduce call volumes by 40%</b>
                </li>
                <li>
                  Achieve a <b>20% y-o-y increase in call reduction</b>
                </li>
                <li>
                  With high-level self-service capabilities, the{" "}
                  <b>
                    chatbot tool increases employee satisfaction level by 4
                    points
                  </b>
                </li>
                <li>
                  Gain up to 20-30% of HR labor reduction in the first year of
                  deployment
                </li>
              </ul>

              <p class="font-section-normal-text-testimonials line-height-18">
                On top of it, our conversational AI chatbot platform is capable
                of auto-resolving 60-80% of repetitive user issues and requests
                in seconds, freeing up valuable and expensive live agents. Thus,
                improving SLA, cost savings, and helping companies scale their
                support in minutes.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                To learn more about our conversational AI solution for your HR
                help desk,{" "}
                <a href="https://assistant.workativ.com/authentication/u/direct-signup">
                  schedule a free demo today.
                </a>
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>

      <AuthorBio />
    </section>
  );
}

const NocodeWrapper = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`nocode_wrapper_upd nocode_wrapper_green ${
        isMobile ? "mb-4" : "mt-4 mb-5"
      }`}
    >
      {isMobile ? (
        <h4 className="font-section-sub-header-small-home mb-0 text-align-center">
          How to build HR Chatbot in 10 mins
        </h4>
      ) : (
        <h4 className="font-section-sub-header-small-home mb-0">
          How to build HR Chatbot in 10 mins
        </h4>
      )}

      <button
        className="font-section-normal-text-testimonials-medium cursor-pointer color-white ml-40"
        type="button"
        onClick={() => (window.location.href = "https://youtu.be/ymkNx2zMLAE")}
      >
        {" "}
        WATCH NOW
      </button>
    </div>
  );
};
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/hr-digital-transformation-guide-2023"
              className="font-section-normal-text-testimonials"
            >
              HR DIGITAL TRANSFORMATION: The ultimate guide - 2023
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/hr-virtual-assistant-guide"
              className="font-section-normal-text-testimonials"
            >
              HR Virtual Assistant: A step-by-step guide to building your HR
              chatbot
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/hr-chatbot-guide"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              HR guide to Conversational AI - How HR Chatbot Solve Remote work
              Challenges
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
export function Faq() {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <React.Fragment>
      <div
        className={`mob_accordian mob_accordian_faq faq_blog_upd ${
          isMobile ? "mt-3" : null
        }`}
      >
        <section className="accordian_landing trial_accordian_landing ">
          {isMobile ? (
            <div className="container">
              <h2
                className={`font-section-sub-header ${
                  isMobile ? "text-align-center" : "text-align-left"
                }`}
              >
                HR Help Desk Frequently Asked Questions
              </h2>
              <div className="col-12 pl-0 faq_landing">
                <Accordion
                  id="accordion"
                  className="accordion pl-0"
                  preExpanded={["a"]}
                >
                  <div className="accordion-wrapper pricing_faq">
                    {faqData.map((data) => (
                      <AccordionItem uuid={data.uuid}>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            {data.header}
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p
                            dangerouslySetInnerHTML={{ __html: data.paragraph }}
                            className="font-section-normal-text line-height-18"
                          />
                          {data.paragraph1 && (
                            <p
                              className="para_2_pricing font-section-normal-text line-height-18"
                              dangerouslySetInnerHTML={{
                                __html: data.paragraph1,
                              }}
                            />
                          )}
                        </AccordionItemPanel>
                      </AccordionItem>
                    ))}
                  </div>
                </Accordion>
              </div>
            </div>
          ) : (
            <>
              <h2
                className={`font-section-sub-header ${
                  isMobile ? "text-align-center" : "text-align-left"
                }`}
              >
                HR Help Desk Frequently Asked Questions
              </h2>
              <div className="col-12 pl-0 faq_landing">
                <Accordion
                  id="accordion"
                  className="accordion pl-0"
                  preExpanded={["a"]}
                >
                  <div className="accordion-wrapper pricing_faq">
                    {faqData.map((data) => (
                      <AccordionItem uuid={data.uuid}>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            {data.header}
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p
                            dangerouslySetInnerHTML={{ __html: data.paragraph }}
                            className="font-section-normal-text line-height-18"
                          />
                          {data.paragraph1 && (
                            <p
                              className="para_2_pricing font-section-normal-text line-height-18"
                              dangerouslySetInnerHTML={{
                                __html: data.paragraph1,
                              }}
                            />
                          )}
                        </AccordionItemPanel>
                      </AccordionItem>
                    ))}
                  </div>
                </Accordion>
              </div>
            </>
          )}
        </section>
      </div>
    </React.Fragment>
  );
}
export const faqData = [
  {
    header:
      "How can I turn my traditional HR help desk into an automated human resource support desk?",
    paragraph:
      "One of the quickest steps to optimize your HR help desk is by applying automation through chatbot integration. Workativ Assistant allows businesses to download a pre-built chatbot from the marketplace and get started instantly.",
    active: "active",
    uuid: "a",
  },
  {
    header:
      "Can my human resource help desk facilitate self-service capabilities?",
    paragraph:
      "Traditional internal help desk for hr does not offer this flexibility to businesses. However, you can create a chatbot, connect it to your HRSM systems, and embed this in your business comms channels such as Teams or Slack. In that case, it is fast and straightforward to facilitate self-service problem-solving capabilities for repetitive employee queries.",
  },
  {
    header:
      "Does an AI-powered conversational chatbot provide flexibility for agent handover?",
    paragraph: "Yes, it is easy to achieve this feat.",
    paragraph1:
      "Without writing a line of code, you can easily set agent handover in minutes. Surprisingly, it does not need you to leave your current communication channel and toggle between the HR help desk to escalate a ticket. Instead, you can create a ticket to be supervised by an agent within a chat app. ",
  },
  {
    header: "How can I get started with an HR help desk chatbot?",
    paragraph:
      "Workativ Assistant is a no-code chatbot builder. Use our tool to create simple to complex workflows and offer personalized options to your users for enhanced employee experience and create business values. Try our free bot to experiment with how to control your HR operations better. ",
  },
];
